<template>
  <div
    class="customer-form-wrapper"
  >
    <not-found
      v-model="isNotFound"
      @cancel="onCancel"
      @reload="onReload"
    />
    <errors
      v-if="!isNotFound"
      :items="formErrors"
      class="px-3 position-relative"
    />
    <b-card v-if="info && itemId === userId">
      <b-alert v-if="isLocked" variant="warning" show class="d-flex align-items-center justify-content-between">
        {{ $t('eLt_locked', { email: isLockedBy.email, id: isLockedBy.by, t: isLockedBy.td }) }}
        <b-btn variant="danger" size="sm" @click="onForceUnlockItem">
          {{ $t('eLt_unlock') }}
        </b-btn>
      </b-alert>
      <b-card-header>
        <b-row align-v="stretch" class="align-items-center">
          <b-col lg="2" md="6" sm="12" class="text-center">
            <b-avatar
              v-if="info.profile"
              class="cursor-pointer rounded-circle img-thumbnail"
              variant="default"
              size="10rem"
              :src="info.profile.avatar_path"
            />
            <media-transform
              v-if="info.profile && info.profile.profile_image"
              :image="info.profile.profile_image"
              @on-updated="onMediaUpdated"
            />
            <div class="mt-2">
              <font-awesome-icon
                :title="isOnline ? 'online' : 'offline'"
                :icon="['fas', 'circle']"
                :class="{
                  'text-success': isOnline,
                  'text-secondary': !isOnline,
                }"
                size="sm"
              /> {{ isOnline ? 'online' : 'offline' }}
            </div>
          </b-col>
          <b-col lg="8" md="6" sm="12">
            <div
              class="row align-items-center"
            >
              <user-title
                :key="keyUserTitle"
                :item-id="itemId"
                :repo="repo"
                :idx="idx"
                :is-locked="isLocked"
              />
            </div>
            <div class="row">
              <div v-if="info.profile" class="col">
                <div class="mb-2">
                  <strong>{{ $t('fCustomer_head_slug') }}:</strong> {{ info.profile.slug_name }}
                </div>
                <div class="h4">
                  <i class="fas fa-share text-dark" />
                  <b-link
                    target="_blank"
                    :href="info.profile.public_url"
                  >
                    {{ info.profile.public_url }}
                  </b-link>
                </div>
                <div class="mb-2">
                  <div class="mb-2">
                    <span class="mr-2">
                      <strong>{{ $t('fCustomer_head_ranking') }}:</strong> {{ real_ranking }}
                    </span>
                    <span
                      v-if="ranking_blocked_value"
                      class="text-danger mr-2"
                    >
                      {{ $t('fCustomer_opt_rankLocked') }} {{ ranking_blocked_value }}
                    </span>
                    <span class="mr-2">
                      <strong>{{ $t('fCustomer_head_stars') }}: </strong> {{ info.profile.stars }}
                    </span>
                    <span
                      v-if="info.profile.stars_changed"
                      class="text-danger mr-2"
                    >
                      {{ $t('fCustomer_opt_starsChanged') }}
                    </span>
                    <span class="mr-2">
                      <strong>{{ $t('fCustomer_head_ghost') }}: </strong>
                      <font-awesome-icon v-if="!!info.profile.is_ghost" :icon="['fas', 'check']" class="text-success" />
                      <font-awesome-icon v-else :icon="['fas', 'xmark']" class="text-danger" />
                    </span>
                    {{ info.profile.club_member }}
                    <span class="mr-2">
                      <b-badge v-if="info.profile.club_member" variant="warning">
                        {{ $t('eProfile_top_wing') }}
                      </b-badge>
                    </span>
                  </div>
                  <div>
                    <strong>{{ $t('fCustomer_head_feedback') }}: </strong>
                    {{ $t('fCustomer_head_positive') }}: <span v-if="info.feedbackPersonal" class="text-success">{{ info.feedbackPersonal.positive }}</span>&nbsp;
                    {{ $t('fCustomer_head_neutral') }}: <span v-if="info.feedbackPersonal">{{ info.feedbackPersonal.neutral }}&nbsp;</span>
                    {{ $t('fCustomer_head_negative') }}: <span v-if="info.feedbackPersonal" class="text-danger">{{ info.feedbackPersonal.negative }}</span>
                  </div>
                </div>
                <div class="mt-2 mb-0">
                  <div class="mb-2">
                    <b>{{ $t('fCustomer_head_userID') }}:</b> {{ userId }} − <b>{{ $t('fCustomer_head_profileID') }}:</b> {{ info.profile.id }} − <b>{{ $t('fCustomer_head_platform') }}:</b>
                    <user-platform
                      :key="keyUserStatus"
                      :item-id="itemId"
                      :options="formDataRepo && formDataRepo.platform"
                      :repo="repo"
                      :idx="idx"
                      :is-locked="isLocked"
                    />
                    - <b>{{ $t('fCustomer_head_tecMobile') }}:</b>
                    <span :class="tecMobile ? 'text-success': 'text-danger'">
                      <font-awesome-icon v-if="tecMobile" :icon="['fas', 'check']" />
                      <font-awesome-icon v-else :icon="['fas', 'xmark']" />
                    </span>
                  </div>
                  <div class="mb-2">
                    <span v-if="info.profile.city_name">
                      <b>{{ $t('fCustomer_head_city') }}:</b> {{ info.profile.city_name }} −
                    </span>
                    <span v-if="info.profile.country">
                      <b>{{ $t('fCustomer_head_country') }}:</b> <market-flag :market="info.profile.country" />
                    </span>
                    <span v-if="info.profile.how_known && info.profile.how_known.trim() !== ''"> − <b>
                      {{ $t('fCustomer_head_howCome') }}:</b> {{ info.profile.how_known }}</span>
                  </div>
                  <div>
                    {{ isHostess ? $t('fCustomer_head_hostess') : $t('fCustomer_head_client') }}
                    <user-status
                      :key="keyUserStatus"
                      :item-id="itemId"
                      :options="formDataRepo && formDataRepo.status"
                      :repo="repo"
                      :idx="idx"
                      :is-locked="isLocked"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="text-center text-md-right text-lg-right py-2 py-md-0 d-flex flex-column align-content-stretch">
            <div>
              <actions-dropdown
                class="mb-2"
                :actions="actionsList"
                @on-action="onAction"
              />
            </div>
            <div class="mt-4">
              <div v-if="createdAt" class="mb-2">
                <b>{{ $t('fCustomer_head_registration') }}:</b> {{ $dateFormat(createdAt) }}<br>
              </div>
              <div v-if="validatedAt" class="mb-2">
                <b>{{ $t('fCustomer_head_validated') }}:</b> {{ $dateFormat(validatedAt) }}<br>
              </div>
              <div v-if="lastActivity" class="mb-2">
                <b>{{ $t('fCustomer_head_lastActivity') }}:</b> {{ $dateFormat(lastActivity) }}<br>
              </div>
              <div v-if="avgUserResponseInMinutes > 0" class="mb-2">
                <b>{{ $t('fCustomer_head_responseTime') }}:</b>
                <span :class="responseTimeClass">
                  {{ displayAvgUserResponse }} ({{ round(avgUserResponseInMinutes, 0) }}
                  {{ $t('fCustomer_head_minutes') }})
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="8" md="6" sm="12">
            <div v-if="utmTrack">
              <b-badge
                v-for="(key, i) in Object.keys(utmTrack)"
                :key="i"
                variant="info"
                class="mr-1"
                pill
              >
                <b class="text-uppercase ">{{ key }}</b>: {{ utmTrack[key] }}
              </b-badge>
            </div>
          </b-col>
          <b-col lg="4" md="6" sm="12" class="d-flex justify-content-end align-items-end py-2 py-md-0">
            <span v-if="passwordLocked" class="text-right">
              <span class="text-danger">
                {{ $t('fCustomer_imper_accessLink') }}
              </span>
              <a
                class="text-danger mb-1 d-block"
                target="_blank"
                :href="`${platform === 'club' ? $config.appClubHost : $config.appSiteHost }/#/login`"
              >
                {{ `${platform === 'club' ? $config.appClubHost : $config.appSiteHost }/#/login` }}
              </a>
              <span class="text-danger mb-1 d-block">
                {{ $t('fCustomer_imper_email') }}: {{ email }}
              </span>
              <span class="text-danger d-block">
                {{ $t('fCustomer_imper_pass') }}: {{ staffTempPass }}
              </span>
            </span>
          </b-col>
        </b-row>
        <b-row
          v-if="affiliate"
          class="mt-2"
        >
          <b-col class="m-0">
            {{ $t('fCustomer_affiliate_title') }}
            <affiliate-link
              :affiliate="affiliate"
            >
              {{ affiliate.name }}
            </affiliate-link>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body
        v-if="info"
        class="px-0"
      >
        <email-and-mobile-duplicates
          v-if="(relatedProfilesAggregated && relatedProfilesAggregated.length)"
          :items="relatedProfilesAggregated"
          class="mb-3"
        />

        <login-possible-duplicates
          v-if="!whitelisted && relatedProfilesDuplicates && relatedProfilesDuplicates.length"
          :items="relatedProfilesDuplicates"
          class="mb-3"
        />

        <possible-clones
          v-if="!whitelisted && relatedProfilesClones && relatedProfilesClones.length"
          :items="relatedProfilesClones"
          class="mb-3"
        />

        <collapse
          v-if="leaveMotivation && leaveMotivation.length"
          id="staffLeaveMotivation"
          class="mb-3 bg-warning"
          :label="`${$t('fCustomer_leave_title')}${leaveMotivation && leaveMotivation.length ? ` (${leaveMotivation.length})` : ''}`"
        >
          <leave-motivation
            :items="leaveMotivation"
            :item-id="itemId"
          />
        </collapse>

        <collapse
          id="staffNotes"
          v-model="staffNotesCollapseState"
          class="mb-3"
          :label="`${$t('fCustomer_note_titleStaff')}${notesPublic && notesPublic.length ? ` (${notesPublic.length})` : ''}`"
        >
          <notes
            ref="notesPublic"
            type="public"
            :items="notesPublic"
            :item-id="itemId"
          />
        </collapse>

        <collapse
          v-if="$gates.hasAnyRole('admin') && $auth.user.is_private_user"
          id="privateNotes"
          class="mb-3"
          :label="`${$t('fCustomer_note_titlePriv')}${notesPrivate && notesPrivate.length ? ` (${notesPrivate.length})` : ''}`"
        >
          <notes
            type="private"
            :items="notesPrivate"
            :item-id="itemId"
          />
        </collapse>

        <b-row class="mb-3">
          <b-col class="col-12 col-lg-3">
            <options-list
              :key="keyOptionsList"
              :item-id="itemId"
              :form-data="formDataRepo"
              :repo="repo"
              :idx="idx"
              :is-locked="isLocked"
              class="mb-4"
            />
            <info-list
              :key="keyInfoList"
              :item-id="itemId"
              :form-data="formDataRepo"
              :init-state="oldForm"
              :repo="repo"
              :idx="idx"
              :is-locked="isLocked"
              @refresh="$fetch"
            />
          </b-col>
          <b-col class="col-12 col-lg-9">
            <div class="text-center row mb-3 py-2 py-lg-0">
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-wallets
                  v-if="isClient"
                  :item-id="itemId"
                  :info="info"
                  class="w-100"
                  :is-locked="isLocked"
                  @save="onSubmit"
                />
                <card-boost
                  v-else-if="isHostess"
                  :item-id="itemId"
                  :form-data="formDataRepo"
                  :info="info"
                  class="w-100"
                  :is-locked="isLocked"
                  @save="onSubmit"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-spent
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowPayments"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-credits
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowCreditHistory"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-chat
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowTalks"
                />
              </div>

              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-chat-support
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowSupportChat"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-reports
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowReports"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-feedbacks
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowFeedbacks"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-invitation-history
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowInvitationHistory"
                />
              </div>

              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-notes
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowNotes"
                />
              </div>
              <div v-if="hasCustomFreeLikeMessage" class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-custom-free-like-message
                  :item-id="itemId"
                  class="w-100"
                  @on-approve="onApproveFreeLikeMessage"
                  @on-decline="onDeclineFreeLikeMessage"
                  @on-update="onUpdateFreeLikeMessage"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-sms
                  :item-id="itemId"
                  class="w-100"
                  @on-show="onShowSmsHistory"
                  @on-new="onShowSmsNew"
                />
              </div>
              <div class="col-12 col-md-6 col-lg-3 p-2 d-flex align-items-stretch">
                <card-selfie
                  :item-id="itemId"
                  class="w-100"
                />
              </div>
              <div class="col-12 p-2 pt-4 d-flex align-items-stretch">
                <description
                  :key="keyDescription"
                  :item-id="itemId"
                  :form-data="formDataRepo"
                  :init-state="oldForm"
                  :repo="repo"
                  :idx="idx"
                  :is-locked="isLocked"
                  class="w-100"
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <gallery-media
          :media="media"
          :profile-id="info.profile && info.profile.id"
          :form-data="formDataRepo"
          :user="{ id: itemId, display_name }"
          :can-validate="!isProfileVerified && info.profile.selfie_path !== ''"
          @on-updated="onMediaUpdated"
        />

        <chart-credits
          v-if="isHostess"
          :idx="idx"
          :item-id="itemId"
        />

        <logs
          :idx="idx"
          :item-id="itemId"
        />

        <invitation-history
          v-model="invitationHistoryState"
          :idx="idx"
          :user-id="itemId"
          :info="info"
          :parent-title="displayTitle"
        />

        <reports
          v-model="reportsState"
          :idx="idx"
          :user-id="itemId"
          :info="info"
          :parent-title="displayTitle"
          @update="onInfoUpdate"
        />

        <feedbacks
          v-model="feedbacksState"
          :idx="idx"
          :user-id="itemId"
          :info="info"
          :parent-title="displayTitle"
          @update="onInfoUpdate"
          @delete="onFeedbackDelete"
        />

        <payments
          v-model="paymentsState"
          :idx="idx"
          :user-id="itemId"
          :parent-title="displayTitle"
          @update="onInfoUpdate"
        />

        <credit-history
          v-model="creditHistoryState"
          :idx="idx"
          :user-id="itemId"
          :parent-title="displayTitle"
        />

        <talks
          v-model="talksState"
          :idx="idx"
          :user-id="itemId"
          :parent-title="displayTitle"
        />

        <user-notes
          v-model="userNotesState"
          :idx="idx"
          :user-id="itemId"
          :info="info"
          :parent-title="displayTitle"
        />

        <user-sms-history
          v-model="userSmsHistoryState"
          :idx="idx"
          :item-id="itemId"
          :info="info"
          :parent-title="displayTitle"
        />

        <user-sms-new
          v-model="userSmsNewState"
          :user-id="itemId"
          :mobile="mobile"
        />

        <permanent-delete-dialog
          v-model="deletePermanentState"
          :user-id="itemId"
          @finished="onPermanentDeleteFinished"
        />
      </b-card-body>
    </b-card>
    <div
      class="footer w-100"
      :style="footerSize"
    >
      <div class="d-flex bg-light align-items-center px-3 py-2 w-100">
        <whatsapp-dropdown
          v-if="info && info.profile"
          :url="info.profile.public_url"
          :phone-number="mobile"
        />
        <strong class="mr-auto" />
        <b-btn
          variant="primary"
          size="sm"
          :disabled="isActionsDisabled"
          @click="onSubmitClose"
        >
          {{ $t('eDoc_form_a_submitClose') }}
        </b-btn>
        <b-btn
          variant="primary"
          size="sm"
          class="ml-1"
          :disabled="isActionsDisabled"
          @click="onSubmit"
        >
          {{ $t('eDoc_form_a_submit') }}
        </b-btn>
        <b-btn
          variant="secondary"
          size="sm"
          class="ml-1"
          :disabled="busy"
          @click="onCancel"
        >
          {{ $t('eDoc_form_a_cancel') }}
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { round } from 'lodash'

import CardWallets from './CustomerEditForm/Cards/CardWallets'
import CardBoost from './CustomerEditForm/Cards/CardBoost'
import CardCredits from './CustomerEditForm/Cards/CardCredits'
import CardChat from './CustomerEditForm/Cards/CardChat'
import CardChatSupport from './CustomerEditForm/Cards/CardChatSupport'
import CardSpent from './CustomerEditForm/Cards/CardSpent'
import CardNotes from './CustomerEditForm/Cards/CardNotes'
import CardFeedbacks from './CustomerEditForm/Cards/CardFeedbacks'
import CardReports from './CustomerEditForm/Cards/CardReports'
import CardInvitationHistory from './CustomerEditForm/Cards/CardInvitationHistory'
import CardCustomFreeLikeMessage from './CustomerEditForm/Cards/CardCustomFreeLikeMessage'
import CardSms from './CustomerEditForm/Cards/CardSms'
import CardSelfie from './CustomerEditForm/Cards/CardSelfie'

import CreditHistory from './CustomerEditForm/Modals/CreditHistory'
import Feedbacks from './CustomerEditForm/Modals/Feedbacks'
import InvitationHistory from './CustomerEditForm/Modals/InvitationHistory'
import Payments from './CustomerEditForm/Modals/Payments'
import PermanentDeleteDialog from './CustomerEditForm/Modals/PermanentDeleteDialog'
import Reports from './CustomerEditForm/Modals/Reports'
import Talks from './CustomerEditForm/Modals/Talks'
import UserNotes from './CustomerEditForm/Modals/UserNotes'
import UserSmsHistory from './CustomerEditForm/Modals/UserSmsHistory'
import UserSmsNew from './CustomerEditForm/Modals/UserSmsNew'

import OptionsList from './CustomerEditForm/Editable/OptionsList'
import InfoList from './CustomerEditForm/Editable/InfoList'
import Description from './CustomerEditForm/Editable/Description'
import UserTitle from './CustomerEditForm/Editable/UserTitle'
import UserStatus from './CustomerEditForm/Editable/UserStatus'
import UserPlatform from './CustomerEditForm/Editable/UserPlatform'

import Collapse from './CustomerEditForm/Elements/Collapse'
import WhatsappDropdown from './CustomerEditForm/Elements/WhatsappDropdown'
import ActionsDropdown from './CustomerEditForm/Elements/ActionsDropdown'

import EmailAndMobileDuplicates from './CustomerEditForm/EmailAndMobileDuplicates'
import LoginPossibleDuplicates from './CustomerEditForm/LoginPossibleDuplicates'
import Notes from './CustomerEditForm/Notes'
import PossibleClones from './CustomerEditForm/PossibleClones'
import GalleryMedia from './CustomerEditForm/GalleryMedia'
import LeaveMotivation from './CustomerEditForm/LeaveMotivation'
import ChartCredits from './CustomerEditForm/ChartCredits'
import Logs from './CustomerEditForm/Logs'

import { customerFormFields } from '~/store/forms/customerEdit'
import { GlobalForm, mapComputedFormFields, mapComputedStateFields } from '~/mixins/globalForm'
import { isServer } from '@/mixins/helpers'

const globalForm = GlobalForm('customerEdit', customerFormFields)

export default {
  ...globalForm,
  name: 'CustomerEditForm',
  components: {
    // CardLogs,
    ChartCredits,
    Logs,
    WhatsappDropdown,
    ActionsDropdown,
    LeaveMotivation,
    GalleryMedia,
    InfoList,
    OptionsList,
    CreditHistory,
    PossibleClones,
    Notes,
    Reports,
    Feedbacks,
    LoginPossibleDuplicates,
    EmailAndMobileDuplicates,
    Collapse,
    InvitationHistory,
    Payments,
    Talks,
    UserNotes,
    UserSmsHistory,
    UserSmsNew,
    CardWallets,
    CardBoost,
    CardCredits,
    CardChat,
    CardChatSupport,
    CardSpent,
    CardFeedbacks,
    CardReports,
    CardInvitationHistory,
    CardNotes,
    CardCustomFreeLikeMessage,
    CardSms,
    CardSelfie,
    Description,
    UserTitle,
    UserStatus,
    UserPlatform,
    PermanentDeleteDialog
  },
  props: {
    ...globalForm.props
  },
  data () {
    return {
      ...globalForm.data(),
      talksState: false,
      paymentsState: false,
      creditHistoryState: false,
      feedbacksState: false,
      reportsState: false,
      invitationHistoryState: false,
      userNotesState: false,
      userSmsHistoryState: false,
      userSmsNewState: false,
      deletePermanentState: false,
      staffNotesCollapseState: false
    }
  },
  computed: {
    ...globalForm.computed,
    ...mapComputedFormFields('customerEdit', [
      'whitelisted'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'type',
      'info',
      'notes',
      'affiliate',
      'relatedProfiles',
      'logs',
      'utmTrack',
      'createdAt',
      'deletedAt',
      'validatedAt',
      'lastActivity',
      'userId',
      'platform',
      'tecMobile',
      'passwordLocked',
      'staffTempPass',
      'media',
      'supportChatId',
      'smsBatch'
    ]),
    ...mapGetters({
      customersOnline: 'staffUser/customersOnline',
      sidebarState: 'layout/sidebarState',
      deviceInfo: 'device'
    }),
    displayTitle () {
      let title
      if (this.title) {
        title = this.title
      } else {
        let type = 'user'
        if (this.info?.profile?.sex) {
          type = this.info?.profile?.sex === 'F'
            ? this.$t('fCustomer_head_hostess')
            : this.$t('fCustomer_head_client')
        }
        const id = this.itemId !== null
          ? ` - ID:${this.itemId}`
          : ''
        title = `${
          this.itemId !== null
            ? '' // this.$t('eDoc_form_labelEdit')
            : this.$t('eDoc_form_labelNew')
        } ${type} ${id}`
      }
      if (this.isProfileVerified) {
        title += ` - <span class="badge badge-success">${this.$t('eProfile_verified')}</span>`
      } else {
        title += ` - <span class="badge badge-danger">${this.$t('eProfile_notVerified')}</span>`
      }
      return title
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    isProfileVerified () {
      return this.info?.profile?.profile_verified || false
    },
    footerSize () {
      let style = ''
      if (this.isMobile) {
        style = 'width: 100vw !important;'
      } else {
        style = `width: calc(100vw - ${this.sidebarState ? 300 : 55}px) !important;`
      }
      return style
    },
    relatedProfilesDuplicates () {
      return this.relatedProfiles?.duplicates || []
    },
    relatedProfilesClones () {
      return this.relatedProfiles?.clones || []
    },
    relatedProfilesAggregated () {
      return this.relatedProfiles?.aggregated || []
    },
    leaveMotivation () {
      return (this.info.profile && this.info.profile.leave_motivation) || []
    },
    notesPublic () {
      return (this.notes && this.notes.public) || []
    },
    notesPrivate () {
      return (this.notes && this.notes.private) || []
    },
    avgUserResponseInMinutes () {
      return (
        this.info.profile &&
        this.info.profile.avg_user_response_in_minutes
      ) || 0
    },
    displayAvgUserResponse () {
      return (
        this.info.profile &&
        this.info.profile.display_avg_user_response
      ) || ''
    },
    responseTimeClass () {
      let $className = ''
      if (this.avgUserResponseInMinutes >= 0 && this.avgUserResponseInMinutes <= (12 * 60)) {
        $className = 'text-dark-green'
      } else if (this.avgUserResponseInMinutes > (12 * 60) && this.avgUserResponseInMinutes <= (48 * 60)) {
        $className = 'text-light-green'
      } else if (this.avgUserResponseInMinutes > (48 * 60) && this.avgUserResponseInMinutes <= (72 * 60)) {
        $className = 'text-orange'
      } else if (this.avgUserResponseInMinutes > (72 * 60)) {
        $className = 'text-red'
      }
      return $className
    },
    keyOptionsList () {
      return this.keys.OptionsList
    },
    keyInfoList () {
      return this.keys.InfoList
    },
    keyUserTitle () {
      return this.keys.UserTitle
    },
    keyUserStatus () {
      return this.keys.UserStatus
    },
    keyUserPlatform () {
      return this.keys.UserPlatform
    },
    keyDescription () {
      return this.keys.Description
    },
    width () {
      return !isServer
        ? document.body.offsetWidth - 55 - (15 * this.idx - 1)
        : 0
    },
    isActionsDisabled () {
      // deactivate save actions if checks not passed
      const isChecksPassed = this.formCheck()
      return !this.userId ||
        this.busy ||
        this.dirtyKey === 0 ||
        this.isLocked ||
        !isChecksPassed
    },
    actionsList () {
      return [
        {
          name: 'impersonate',
          enabled: !this.isLocked && !this.passwordLocked
        },
        {
          name: 'impersonate-unlock',
          enabled: !this.isLocked && this.passwordLocked
        },
        {
          name: 'delete',
          enabled: !this.isLocked && !this.deletedAt
        },
        {
          name: 'restore',
          enabled: !this.isLocked && !!this.deletedAt
        },
        {
          name: 'delete-permanent',
          enabled: !this.isLocked
        }
      ]
    },
    isOnline () {
      return this.customersOnline?.includes(this.itemId) || false
    },
    hasCustomFreeLikeMessage () {
      return (!!this.info && this.info.custom_freelike_message && this.info.custom_freelike_message?.message) || false
    },
    isClient () {
      return this.itemRepo?.type === 'client' || false
    },
    isHostess () {
      return this.itemRepo?.type === 'hostess' || false
    },
    actionList () {
      return []
    }
  },
  watch: {
    ...globalForm.watch,
    displayTitle (n) {
      this.setLayoutPageTitle(n)
      this.setLayoutWindowTitle(n)
    },
    isFetched (n) {
      if (n) {
        this.batchSms({ itemId: this.itemId })
      }
    }
  },
  mounted () {
    addEventListener('beforeunload', () => {
      this.$emit('on-cancel')
      return 'Please press the Logout button to logout.'
    }, { capture: true })
    // open notes
    if (this.$route.hash === '#notes') {
      this.staffNotesCollapseState = true
    }
  },
  methods: {
    ...globalForm.methods,
    ...mapActions({
      fill: 'forms/customerEdit/fill',
      lockPassword: 'forms/customerEdit/lockPassword',
      unlockPassword: 'forms/customerEdit/unlockPassword',
      approveFreeLikeMessage: 'forms/customerEdit/approveFreeLikeMessage',
      declineFreeLikeMessage: 'forms/customerEdit/declineFreeLikeMessage',
      updateFreeLikeMessage: 'forms/customerEdit/updateFreeLikeMessage',
      batchSms: 'forms/customerEdit/batchSms'
    }),
    fillData () {
      if (this.itemRepo && this.itemRepo.id === this.itemId) {
        this.fill({
          id: this.itemId,
          data: {
            ...this.itemRepo,
            formData: this.formDataRepo
          }
        })
      }
    },
    async onMediaUpdated () {
      await this.$fetch(true)
      await this.call({
        repo: this.repo,
        method: 'show',
        id: this.itemId,
        idx: this.idx
      })
      this.$nextTick(() => {
        // this.getDirty({ itemId: this.itemId, key: `${this.$options.name}.${this.itemId}` })
        this.fillData()
        this.$forceUpdate()
        this.isFetched = true
      })
    },
    onShowTalks () {
      this.talksState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowPayments () {
      this.paymentsState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowCreditHistory () {
      this.creditHistoryState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowFeedbacks () {
      this.feedbacksState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowReports () {
      this.reportsState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowInvitationHistory () {
      this.invitationHistoryState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowSupportChat () {
      if (this.supportChatId) {
        this.$supportChat(this.supportChatId)
      }
    },
    onShowNotes () {
      this.userNotesState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowSmsHistory () {
      this.userSmsHistoryState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onShowSmsNew () {
      this.userSmsNewState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    async onInfoUpdate () {
      this.$store.dispatch('setLoadingEnable', false)
      await this.$fetch()
      this.$store.dispatch('setLoadingEnable', true)
    },
    async onLockPassword () {
      await this.lockPassword(this.itemId)
      await this.$fetch()
    },
    async onUnlockPassword () {
      await this.unlockPassword(this.itemId)
      await this.$fetch()
    },
    onFeedbackDelete (id) {
      //
    },
    onDeleteCustomer () {
      let confirm
      if (this.deletedAt) {
        confirm = this.$confirmWarning({
          title: this.$t('eDoc_list_restoreConfirmTitle'),
          body: this.$t('eDoc_list_restoreConfirmBody', { id: this.itemRepo.id }),
          okTitle: this.$t('eDoc_list_restoreConfirmOk'),
          cancelTitle: this.$t('eDoc_list_restoreConfirmCancel')
        })
      } else {
        confirm = this.$confirmDanger({
          title: this.$t('eDoc_list_deleteConfirmTitle'),
          body: this.$t('eDoc_list_deleteConfirmBody', { id: this.itemRepo.id }),
          okTitle: this.$t('eDoc_list_deleteConfirmOk'),
          cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
        })
      }
      confirm
        .then(async (value) => {
          if (value) {
            this.flushError()
            try {
              this.busy = true
              await this.call({
                repo: this.repo,
                method: 'delete',
                id: this.itemRepo.id
              })
              await this.$fetch(true)
            } catch (e) {} finally {
              this.busy = false
            }
          }
        })
    },
    onDeleteCustomerPermanent () {
      this.$confirmDanger({
        title: this.$t('eDoc_list_deleteConfirmTitle'),
        body: this.$t('fCustomer_deletePermanentConfirmBody', {
          type: this.info?.profile.sex === 'F' ? this.$t('fCustomer_head_hostess') : this.$t('fCustomer_head_client'),
          name: this.display_name
        }),
        okTitle: this.$t('eDoc_list_deleteConfirmOk'),
        cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
      })
        .then((value) => {
          if (value) {
            this.deletePermanentState = true
          }
        })
    },
    async onPermanentDeleteFinished () {
      await this.refreshList({ repo: 'customers' })
      this.state = false
      // await slider transition
      setTimeout(() => {
        this.flushData()
        this.$emit('on-cancel')
        window.close()
      }, 300)
    },
    async onApproveFreeLikeMessage () {
      await this.approveFreeLikeMessage({ itemId: this.itemId })
      this.$fetch()
    },
    async onDeclineFreeLikeMessage () {
      await this.declineFreeLikeMessage({ itemId: this.itemId })
      this.$fetch()
    },
    async onUpdateFreeLikeMessage (message) {
      await this.updateFreeLikeMessage({ itemId: this.itemId, message })
      this.$fetch()
    },
    round,
    // method reloaded to close tab on close document
    onCancel () {
      if (this.dirty && Object.keys(this.dirty).length) {
        this.$confirmWarning({
          title: this.$t('eDoc_list_changedConfirmTitle'),
          body: this.$t('eDoc_list_changedConfirmBody'),
          okTitle: this.$t('eDoc_list_changedConfirmOk'),
          cancelTitle: this.$t('eDoc_list_changedConfirmCancel')
        })
          .then((value) => {
            if (value) {
              this.windowTerminate()
            }
          })
      } else {
        this.windowTerminate()
      }
    },
    // method reloaded to close tab on close document
    async onSubmitClose () {
      this.flushError()
      if (this.itemRepo && this.itemRepo.id) {
        try {
          this.busy = true
          await this.call({
            repo: this.repo,
            method: 'update',
            id: this.itemRepo.id,
            payload: this.dirty
          })
          // Whisp to reload form
          this.$repo.reloadItem('customerEdit', this.repo, this.itemRepo.id)
        } catch (e) {} finally {
          this.busy = false
          this.windowTerminate()
        }
      } else {
        this.windowTerminate()
      }
    },
    async windowTerminate () {
      const nonSkipableErrors = this.errors.filter(i => i.code !== 404)
      if (!nonSkipableErrors || nonSkipableErrors.length === 0) {
        // check is record loaded
        if (this.itemRepo?.id) {
          // reload form
          await this.$fetch()
          this.fillData()
          // share apply form
          this.$repo.applyForm('customerEdit', this.itemRepo.id, this.form)
          this.applyForm({ itemId: this.itemId, formData: this.form })
        }
        // Unlock item
        if (this.isLockedMy) {
          this.$repo.unlockMyItem('customerEdit', this.repo, this.itemId)
        }
        // // Whisp to reload form
        this.$repo.reloadItem('customerEdit', this.repo, this.itemRepo?.id || this.itemId)
        // hide
        this.state = false
        // await slider transition
        setTimeout(() => {
          this.flushDirty({ itemId: this.itemId })
          this.flushData()
          this.$emit('on-cancel')
          this.$nextTick(() => {
            window.close()
          })
        }, 300)
      }
    },
    formCheck () {
      let passed = true
      // Hostess message cost can not be 0
      if (parseInt(this.dirty?.characterMsgCost, 10) === 0 || this.dirty?.characterMsgCost === '') {
        passed = false
      }
      // Hostess stars must be between 0 and 5
      if (this.dirty?.stars && (this.dirty?.stars < 0 || this.dirty?.stars > 5)) {
        passed = false
      }

      return passed
    },
    onAction (actionName) {
      switch (actionName) {
        case 'impersonate':
          this.onLockPassword()
          break
        case 'impersonate-unlock':
          this.onUnlockPassword()
          break
        case 'delete':
          this.onDeleteCustomer()
          break
        case 'restore':
          this.onDeleteCustomer()
          break
        case 'delete-permanent':
          this.onDeleteCustomerPermanent()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-dark-green {
  color: #0f6848;
}
.text-light-green {
  color: #1cc88a;
}
.text-orange {
  color: #fd7e14;
}
.text-red {
  color: #be2617;
}

.customer-form-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100svh - 90px);

  .footer {
    display: inline-flex;
    position: fixed;
    bottom: 0;
    transition: width .3s ease-in-out;
  }
}
</style>
